import Utility from "../../../shared/utility.js";
import EncryptUtility from "./encrypt-utility";
import AddFavourite from "./addFav";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      icciShow: false,
      esnShow: false,
      eslNum: "",
      icciNum: "",
      userId: EncryptUtility.localStorageDecrypt("userID"),
      isPrinter: true,
      unitIdentifier: "",
      validatedUnitIdent: false,
      isFormValid: false,
      pass: null,
      unitIdentifyValid: false,
      btnName: "",
      searchClicked: false,
      mainKey: null,
      serialNum: "",
      type: 0,
      searchData: [],
      BCNName: "",
      changeClicked: false,
      changeItem: "",
      changeValue: "",
      changeItemVal: "",
      changeValValid: false,
      bcnShow: false,
      serialShow: false,
      primaryName: EncryptUtility.localStorageDecrypt('primaryName'),
      max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Max Length of 50 character"],
      max36Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 36 || "Max Length of 36 character"],
      showPrimary: true,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() { },
  methods: {
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.unitIdentifier = "";
      this.validatedUnitIdent = false;
      this.pass = null;
      this.$refs.UnitIdentifierForm.resetValidation();
      this.isFormValid = false;
      this.btnName = "";
      this.searchClicked = false;
      this.mainKey = null;
      this.serialNum = "";
      this.type = 0;
      this.searchData = [];
      this.changeClicked = false;
      this.changeItem = "";
      this.changeValue = "";
      this.changeValValid = false;
      this.changeItemVal = "";
      this.bcnShow = false;
      this.serialShow = false;
      this.$refs.ChangeValueForm.resetValidation();
    },
    //Searching the Identifier
    searchIdentifer() {
      if (!this.$refs.UnitIdentifierForm.validate()) return false;
      this.pass = 1;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchUnitIdentifier = {
        pass: parseInt(this.pass),
        unit: this.unitIdentifier,
        main_key: 0,
        UserId: parseInt(this.userId),
        serialno: this.serialNum,
        type: parseInt(this.type),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
      };
      this.axios
        .post("/ut/unitidentifier_change", searchUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.searchData = responsedata.Info;
            this.searchData[0].bcned ? this.bcnShow = true : this.bcnShow = false
            this.searchData[0].bcn !== "" ? this.BCNName = this.searchData[0].bcn : ""
            this.searchData[0].serialed ? this.serialShow = true : this.serialShow = false
            this.searchData[0].serialno !== "" ? this.serialNum = this.searchData[0].serialno : ""
            this.searchData[0].esned ? this.esnShow = true : this.esnShow = false
            this.searchData[0].esn !== "" ? this.eslNum = this.searchData[0].esn : ""
            this.searchData[0].iccided ? this.icciShow = true : this.icciShow = false
            this.searchData[0].iccid !== "" ? this.icciNum = this.searchData[0].iccid : ""
            this.searchClicked = true;
            this.isFormValid = true;
            this.validatedUnitIdent = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Change BCN button click event
    onChangeBCNClick(item) {
      this.changeItemVal = "";
      this.changeItemVal = item;
      this.changeItem = "BCN";
      this.changeClicked = true;
      this.type = 1;
    },
    //Change BCN button click event
    onChangeSerialNoClick(item) {
      this.changeItemVal = "";
      this.changeItemVal = item;
      this.changeItem = "Serial#";
      this.changeClicked = true;
      this.type = 2;
    },
    onChangeEslNoClick(item) {
      this.changeItemVal = "";
      this.changeItemVal = item;
      this.changeItem = "Esn#";
      this.changeClicked = true;
      this.type = 3;
    },
    onChangeIcciClick(item) {
      this.changeItemVal = "";
      this.changeItemVal = item;
      this.changeItem = "ICCID#";
      this.changeClicked = true;
      this.type = 4;
    },
    //Submit Change value
    submitChangeValue() {
      if (!this.$refs.ChangeValueForm.validate()) return false;
      this.pass = 2;
      if (EncryptUtility.localStorageDecrypt('primaryLabelSize') !== null && EncryptUtility.localStorageDecrypt('primaryLabelSize') !== undefined) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt('primaryLabelSize');
      }
      if (EncryptUtility.localStorageDecrypt('primaryPrinterType') !== null && EncryptUtility.localStorageDecrypt('primaryPrinterType') !== undefined) {
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt('primaryPrinterType');
      }
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let changeUnitIdentifier = {
        pass: parseInt(this.pass),
        unit: this.changeItemVal,
        main_key: parseInt(this.searchData[0].main_key),
        UserId: parseInt(this.userId),
        serialno: this.changeValue,
        type: parseInt(this.type),
        lbl_size: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
      };
      this.axios
        .post("/ut/unitidentifier_change", changeUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            if (this.primaryName !== null && this.primaryName !== undefined) {
              PrinterDetails.printLabelData(responsedata.Info[0].label, this.primaryName);
            }
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.resetFunction();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
